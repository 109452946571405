<template>
  <div class="container-stats">
    <div class="container-statistics">
      <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
        <!--<li>
          <router-link to="/solutions/in/platform/models">
            <span>Model</span>
          </router-link>
        </li>-->
        <li>
          <router-link to="/solutions/in/platform/allstats">
            <span>Model Results</span>
          </router-link>
        </li>
        <li class="ProximaNovaBold">Attribution</li>
      </ul>
        <div class="d-flex flex-row" style="height: 40%">
        <div class="w-100 pt-4">
          <w-white-dropdown
            :options="brandDropdown"
            :placeHolder="'Select'"
            :selectedOption="selectedBrand"
            :labelText="'KPI'"
            style="float: right"
            class="mr-3"
            @input="selectBrand($event)"
          ></w-white-dropdown>
        </div>

        <div class="pt-4">
          <w-white-dropdown
            :options="mediaDropdown"
            :placeHolder="'Select'"
            :selectedOption="selectedMedia"
            :labelText="'Media'"
            class="mr-3"
            @input="selectMedia($event)"
          ></w-white-dropdown>
        </div>
      </div>
    </div>

    <div class="container-line-chart w-100">
      <div class="title">Attribution</div>
      <div class="col-md-12 col-lg-12 col-sm-12 my-4">
        <div class="card pb-5 pb-lg-5 h-100">
          <div class="">
            <ColumnChart :chartData="allMobelBarChart" />
          </div>
        </div>
      </div>
      <div class="text-right my-5 w-100 col-md-12 col-lg-12 col-sm-12 my-4">
        <w-button
          :buttonText="'Ensemble'"
          class="my-5"
          :buttonLoader="'normal'"
          @buttonClicked="nextToTheInsights"
        >
        </w-button>
      </div>
      <StatusModal ref="solutionModal" @progressDone="doneProgress" />
    </div>
  </div>
</template>
<script>
import Button from "@/components/Profile/Button.vue";
import { AimoSolutionsServices } from "@/services/AimoSolutionsServices.js";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import StatusModal from "@/components/Modal/SolutionModal.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
const aimoSolutionsServices = new AimoSolutionsServices();
export default {
  name: "AllStats",
  components: {
    "w-button": Button,
    ColumnChart,
    StatusModal,
    "w-white-dropdown": WhiteDropdown,
  },
  data() {
    return {
      subId: sessionStorage.getItem("subId"),
        brandDropdown: [
        { text: "Leads", id: "leads" },
        { text: "Sales", id: "sales" },
      ],
         selectedBrand: {text: "Leads", id: "leads" },
      overallDetailsData: [
        { label: "Actual Sales", value: "1131.24 Cr" },
        { label: "Predicted Sales", value: "1091.8 Cr" },
        { label: "Error %", value: "3.48%" },
      ],
      allMobelBarChart: {
        data: [],
        title: {
          text: "Attribution by Channel for All Models(%)",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: [
          "#faacfe",
          "#67e8ff",
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#d81b60",
          "#fb8c00",
        ],
        legend: {
          align: "left",
          verticalAlign: "bottom",
          layout: "horizontal",
          itemMarginTop: 30,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        xAxis: {
          categories: ["2017", "2018", "2019"],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.3,
            dataLabels: {
                enabled: true
            }
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      selectedMarket: {},
    };
  },
  methods: {
      selectBrand(e) {
      this.selectedBrand = e;
      sessionStorage.setItem("solutionsKpiMarket", e)
      this.getOverallAttribution()
      },
    doneProgress() {
      this.$router.push("/solutions/in/platform/stat");
    },
    nextToTheInsights() {
      // this.$refs.solutionModal.open();
      this.$router.push("/solutions/in/platform/stat");
    },
    getOverallAttribution() {
      var market = sessionStorage.getItem("solutionsKpiMarket")
      console.log("market", market)
      aimoSolutionsServices
        .getOverallAttribution(
          "overallattribution",
          this.subId,
this.selectedBrand.id
        )
     .then((res) => {
          var res_data = res.response;
          this.allMobelBarChart.data = [];
          this.allMobelBarChart.xAxis.categories = [];
          for (var i = 0; i < res_data.length; i++) {
            this.allMobelBarChart.xAxis.categories.push(res_data[i].name);
            for (var j = 0; j < res_data[i].value.length; j++) {
              var dataFind = this.allMobelBarChart.data.find(
                (x) => x.name === res_data[i].value[j].name
              );
              if (dataFind !== undefined) {
                dataFind.data.push(parseInt(res_data[i].value[j].value));
              } else {
                this.allMobelBarChart.data.push({
                  name: res_data[i].value[j].name,
                  data: [parseInt(res_data[i].value[j].value)],
                });
              }
            }
          }
        })
        .catch(() => {});
    },
  },
  created() {
    this.selectedMarket = JSON.parse(
      sessionStorage.getItem("solutionsKpiMarket")
    );
    this.getOverallAttribution();
  },
};
</script>

<style lang="css" scoped>
.container-stats {
  width: 93%;
  margin: 71px auto 0 auto;
}

.container-statistics {
  padding-top: 1px;
}

.title {
  font-family: ProximaNovaBold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
  margin-top: 48px;
  margin-bottom: 32px;
}

.wrapper-statistics {
  box-shadow: 0px 0px 16px -13px rgb(0 0 0);
  border-radius: 4px;
  min-height: 126px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.card-heading {
  margin: 20px 0px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
.card {
  box-shadow: 0 0px 8px 0 #c9ced5;
  background-color: #fff;
}
.item {
  display: flex;
  width: 20%;
  position: relative;
}
.perf-card-wrapper {
  margin: 0px 24px;
}

.perf-card {
  width: 277px;
  height: 97px;
  box-shadow: 0 0 8px 0 #c9ced5;
  display: flex;
  align-items: center;
  padding-left: 21px;
  margin-top: 44px;
}

.item:not(:last-child):before {
  content: "";
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 8px;
}

.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8394b4;
  padding-bottom: 1rem;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
</style>
